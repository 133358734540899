import React from "react";
import useStyles from "../../assets/login/styles";
// logo
import ehiiaLogo from "../../assets/icons/ehiia_logo.svg";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { TableHeadCell, TableHeadRow } from "mui-datatables";
export default function PrivacyPolicy() {
  const navigate = useNavigate();
  var classes = useStyles();
  const gotoHome = () => {
    navigate("/");
  };
  return (
    <section className={classes.mainSectionContainer}>
      <div
        style={{
          // width: "1120px",
          cursor: "pointer",
          justifyItems: "center",
          position: "relative",
          margin: "auto",
          marginBottom: "16px",
        }}
      >
        <img
          style={{ width: "170.52px", height: "34.17px" }}
          onClick={gotoHome}
          src={ehiiaLogo}
          alt="icons"
        />
      </div>
      <Grid container>
        <Grid item md={12}>
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: 600, marginTop: "10px" }}
          >
            Provide Digital Limited: Privacy Notice for the ImpactEQ Users
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ padding: "10px 16px", fontWeight: 600 }}
          >
            Effective Date: August 2024
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Provide Digital Limited (“Provide Digital”, “We”,”Us”) as the
            developer and owner of ImpactEQ digital platform,
            including the web portal (the main domain of <a href="https://impacteq.co.uk/" target="">https://impacteq.co.uk/</a>
            its sub-domains) (collectively called “ImpactEQ Application”),
            understands that your privacy is important to you and that you care
            about how your information is used and shared. We respect and value
            the privacy of everyone who uses ImpactEQ and will only collect and
            use information in ways that are useful to you and in a manner
            consistent with your rights and our obligations under Data
            Protection law.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Provide Digital plays a dual role when it comes to processing. In
            some situations, we act as a Controller, in others, we are the
            Processor.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            When we are a Processor of personal data, we are doing so purely on
            the instruction of another company (the Controller). This will
            usually be the data of the Service Users, Client Contacts and Client
            Admin Users of Client Organisations.{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Provide Digital, does on occasion, act as a Controller. That will
            usually be the contact data of the company and/or its
            representatives where ImpactEQ Application has been deployed and
            then technical data when you use our app.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Being a Controller means that we are trusted to look after and deal
            with your personal information in accordance with this notice. We
            determine the ways and means of processing your data and must
            therefore be accountable for it.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            This Privacy Notice refers to the data we process data as a
            Controller only and describes what personal information we collect
            and hold about you, how we use it, who can access it and your rights
            with regards to how we process your information.{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            The General Data Protection Regulation (GDPR) also gives you the
            right to lodge a complaint with a supervisory authority, in a
            Country you work, normally live or if any alleged infringement of
            data protection laws occurred in the relevant state. The supervisory
            authority in the UK is the Information Commissioner who may be
            contacted at <a href="https://ico.org.uk/concerns/" target="">https://ico.org.uk/concerns/</a> or telephone: 0303 123
            1113.
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontWeight: 600,
              padding: "10px 16px",
              textAlign: "justify",
            }}
          >
            Who we are
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We are Provide Digital Limited, a company incorporated in England
            and Wales with registered number 12377709 at Companies House and
            whose registered office is at 900 The Crescent, Colchester Business
            Park, Colchester, CO4 9YQ, United Kingdom.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            If you have any questions about this Privacy Notice, please contact
            us by post (Postal Address: 900 The Crescent, Colchester Business
            Park, Colchester, CO4 9YQ, United Kingdom); or email (
            <a href="provide.infogov@nhs.net" target="">
              provide.infogov@nhs.net
            </a>
            ). Please ensure your query is clear, particularly if it is a
            request for information about the personal data we hold about you.
            We hope that we can resolve any query or concern you raise about our
            use of your information.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Provide Digital is registered with the Information Commissioner’s
            Office (ICO) to process personal and special categories of
            information (Registration number: ZB407604).{" "}
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            Why we keep personal information about you
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We may use the personal information we have collected about you for
            the following purpose/s:{" "}
          </Typography>
          <ol>
            <li>
              To understand what you want to build, what features your product
              should have, and what purpose it will serve.
            </li>
            <li>
              To establish a contract and provide you access to the application
            </li>
            <li>
              To optimise the performance of our app, monitor it for security
              purposes and drive improvements for our users{" "}
            </li>
            <li>
              To provide operation and/or technical support for the tickets
              raised
            </li>
            <li>To send you service updates</li>
          </ol>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Generally, we do not rely on consent as a legal basis for processing
            your personal data although we will get your consent before sending
            third party direct marketing communications to you via email or text
            message. You have the right to withdraw consent to marketing at any
            time by contacting us.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us. If we need to use your personal data for an unrelated
            purpose, we will notify you and we will explain the legal basis
            which allows us to do so.
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            What Information do we keep about you?
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We may collect and process the following types of personal
            information:{" "}
          </Typography>
          <ol>
            <li>Name of the company or representative/s</li>
            <li>Email Address of the company or representative/s</li>
            <li>Phone Number of the company or representative/s</li>
            <li>Address of the company</li>
          </ol>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We may collect and process non-personal data:
          </Typography>
          <ol >
            <li>Logo and other branding material of the company</li>
          </ol>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Apart from the above data, we also process data about the way you
            use our app, including your IP address and browsing time. At Provide
            Digital, we believe in being transparent about how we process data
            about you and how we use any third party to process the data.{" "}
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            How we collect Information about you{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We may collect personal information about you in a number of ways:{" "}
          </Typography>
          <ol >
            <li>
              When you make use of our contact form or demo request form to
              request information from us{" "}
            </li>
            <li>When you provide the data to enter into a contract with us </li>
            <li>
              If you sign up on one of our ImpactEQ Application that we provide
              and support
            </li>
            <li>When you contact us by phone</li>
          </ol>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            Our Lawful basis for processing your data
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We may rely on one or more of the following lawful bases for
            processing your personal information:
          </Typography>
          <ul>
            <li>
              Your consent. You are able to remove your consent at any time. You
              can do this by contacting us through email at{" "}
              <a href="provide.infogov@nhs.net" target="">
                provide.infogov@nhs.net
              </a>
            </li>
            <li>
              We have a contractual obligation and the processing is necessary
              for the performance of Services we provide pursuant to a contract
              we have entered into with you or your representative.
            </li>
            <li>We have a legal obligation</li>
            <li>We have a legitimate interest assessment</li>
          </ul>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            The specific lawful bases will depend on the engagement or services
            you have with us.
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            How and where we hold your personal information
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We create and hold your records electronically and sometimes in hard
            copy where necessary.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Any records we hold about you are held securely and are only
            accessible to those who have a legitimate need to access the
            information.{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Your information will be kept securely and will remain in the UK. We
            generally store the information provided via ImpactEQ Application in
            Amazon Web Services (AWS) Cloud datacentres located in the UK where
            data is encrypted to a high standard (AES256). We also use certain
            third party platforms to share and store information (if required).
            Please refer to section – ‘Do we share your Information?’ for
            further details.{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We do not typically transfer your personal data outside the UK.
            However, should we need to transfer your data outside the UK, we
            will ensure that the appropriate legal safeguard is in place to
            allow the transfer.{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            All of our staff and contractors receive appropriate and on-going
            training to ensure they are aware of their personal responsibilities
            and have contractual obligations to uphold confidentiality,
            enforceable through disciplinary procedures if necessary. Staff only
            have access to personal information where it is appropriate to their
            role and is strictly on a need-to-know basis.
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            How we use your Information{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We ensure that we process your data lawfully, fairly and
            transparently in accordance with Data Protection legislation.{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Personal information collected about you may be used for the
            following purpose/s:
          </Typography>
          <ul>
            <li>Performance of a contract with you </li>
            <li>Responding to communications from you</li>
            <li>
              Supplying you with any information or newsletter you have
              subscribed to
            </li>
            <li>Providing support for our product/s</li>
          </ul>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            If we need to use your personal information for any reasons beyond
            those stated above, we will discuss this with you and ask for your
            permission to do so.
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            Do we share your Information?{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We may share your personal information with other companies within
            our group. This includes our holding company, Provide CIC and its
            subsidiaries.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            These may include payment processing, delivery of goods, search
            engine facilities, cloud storage, advertising and marketing. In some
            cases, the third parties may require access to some or all of your
            data. Where any of your data is required for such a purpose, we will
            take all reasonable steps to ensure that your data will be handled
            safely, securely, and in accordance with your rights, our
            obligations, and the obligations of the third party under the law.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We use third party services for the purposes set out in the table
            below. A list of our external service providers can be found below:{" "}
          </Typography>
          <center>
          <Table style={{ border: "2px solid #dbdbdb", width:'70%' }}>
            <TableHeadRow>
              <TableCell
                style={{ border: "2px solid #dbdbdb", fontWeight: 600 }}
              >
                Service provider
              </TableCell>
              <TableCell
                style={{ border: "2px solid #dbdbdb", fontWeight: 600 }}
              >
                Service
              </TableCell>
            </TableHeadRow>
            <TableRow>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Google Drive
              </TableCell>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Document repository
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Microsoft Email
              </TableCell>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Client email service
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Microsoft OneDrive
              </TableCell>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Document repository
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                SendGrid
              </TableCell>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Email and Customer service
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Crowd Apps
              </TableCell>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Software development
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Amazon Web Services (AWS)
              </TableCell>
              <TableCell style={{ border: "2px solid #dbdbdb" }}>
                Cloud Infrastructure service
              </TableCell>
            </TableRow>
          </Table>
          </center>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            In certain circumstances we may be legally required to share certain
            data held by us, which may include your personal information, for
            example, where we are involved in legal proceedings, where we are
            complying with the requirements of legislation, a court order, or a
            governmental authority. We do not require any further consent from
            you in order to share your data in such circumstances and will
            comply as required with any legally binding request that is made of
            us.
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            How long do we keep your Information?{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. Usually that means we will keep your
            personal data for as long as you have a registered account with
            Provide Digital. We may need to retain your personal data for a
            longer period in the event of a complaint, if we reasonably believe
            there is a prospect of litigation in respect to our relationship
            with you or where we have to keep data for regulatory purposes.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            You can request to delete all your personal data (where applicable)
            by contacting us via email at{" "}
            <a href="provide.infogov@nhs.net" target="">
              provide.infogov@nhs.net
            </a>{" "}
            , and the appropriate personal data will be erased, deleted,
            destroyed, or otherwise disposed of.
          </Typography>
          <Typography
            variant="body1"
            style={{
              padding: "10px 16px",
              textAlign: "justify",
              fontWeight: 600,
            }}
          >
            Your Rights
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            You have the following rights, under the Data Protection
            legislation, in respect of your personal information that we hold
            about you:
          </Typography>
          <ul>
            <li>
              <span style={{ fontWeight: 600 }}>Right to be Informed -</span> to
              be informed about the collection and use of your personal data
            </li>
            <li>
            <span style={{ fontWeight: 600 }}> Right of access -</span> To ask for copies of your personal
              information{" "}
            </li>
            <li>
            <span style={{ fontWeight: 600 }}> Right to rectification -</span> To ask for your personal information
              to be corrected if it is inaccurate or incomplete.{" "}
            </li>
            <li>
            <span style={{ fontWeight: 600 }}> Right to erasure -</span> To ask for your personal information to be
              deleted or erased, where applicable.{" "}
            </li>
            <li>
            <span style={{ fontWeight: 600 }}> Right to restriction of processing -</span> To ask us to restrict the
              use of your personal information in some circumstances.{" "}
            </li>
            <li>
            <span style={{ fontWeight: 600 }}> Right to object to processing -</span> To object to the processing of
              your personal information in certain circumstances. We may use
              automated decision making in processing your personal information
              for some services. You may object to your information being
              subjected to automated processing and you can request a manual
              review of the outcome of an automated decision if you are unhappy
              with it.{" "}
            </li>
            <li>
            <span style={{ fontWeight: 600 }}> Right to data portability -</span> To request your personal
              information to be transferred to other providers on certain
              occasions.
            </li>
            <li> <span style={{ fontWeight: 600 }}> Right to Object</span></li>
            <li>
            <span style={{ fontWeight: 600 }}> Rights in Relation to Automated Decision Making and Profiling -</span>
              not to be subject to a decision based solely on automated
              processing, including profiling, which produces legal effects or
              similarly significantly affects you.
            </li>
          </ul>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            We do not typically charge a fee for exercising your rights except
            in certain circumstances where permitted by Data Protection law. We
            have one month to respond to your request and may extend by another
            two months in certain circumstances.{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            If you wish to exercise any of your rights please contact us on{" "}
            <a href="provide.infogov@nhs.net" target="">provide.infogov@nhs.net</a> or through our data subject request online
            form/portal by clicking Home Page - Portal (ams-sar.com) or by
            writing to our Data Protection Officer at <a href="provide.infogov@nhs.net" target="">provide.infogov@nhs.net</a>{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >
            Should you have a query or wish to lodge a complaint about the use
            of your personal information, please contact our Information
            Governance Team in the first instance at <a href="provide.infogov@nhs.net" target="">provide.infogov@nhs.net</a> or
            write to:<br/>
            Information Governance, Provide HQ, 900 The Crescent, Colchester Business Park, Colchester, Essex, CO4 9YQ.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >If you are still unhappy with the outcome of your enquiry concerning how we process your personal information you can write to:   </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >The Information Commissioner, Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF.  
          Telephone: 0303 123 1113.
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          >ICO Website: <a href="https://www.ico.org.uk" target="">https://www.ico.org.uk</a></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
          <Typography
            variant="subtitle2"
            style={{ padding: "10px 16px", textAlign: "justify" }}
          ></Typography>
        </Grid>
      </Grid>
    </section>
  );
}
