import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Button,
  Fade,
} from "@material-ui/core";
// components
import Widget from "../../../component/Widget";
import PageTitle from "../../../component/PageTitle/PageTitle";

// styles
import useStyles from "./styles";

// api
import Validation from "../../hooks/Validation";
import { updateProfile, getProfile } from "../../store/actions/adminAction";
import { adminReset } from "../../store/reducerSlice/admin";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";

import { useToasts } from "react-toast-notifications";
import Loader from "../../../component/common/loader/Loader";
export default function ProfileDetails() {
  const navigate = useNavigate();
  var classes = useStyles();

  const [profileImage, setLogo] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [orgDataValue, setOrgDataValue] = React.useState([]);
  const inputRef = useRef();
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [adminDetail, setAdminDetail] = useState({
    name: null,
    email: null,
    clientOrgShortName: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      name: adminDetail.name,
      email: adminDetail.email,
      // clientOrgShortName:adminDetail.clientOrgShortName
    });
  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(getProfile()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setAdminDetail(payload?.data?.data);
        setOrgDataValue(payload?.data?.data?.subOrg?.ehiiaVersion);
        setIsLoading(false);
      }
      console.log("adminDetail", adminDetail);
    });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  //Api call
  useEffect(() => {
    setIsUploading(true);
    console.log("file profileImage", profileImage);
    let formData = new FormData();
    if (adminDetail?.adminId) {
      formData.append("clientId", adminDetail?.clientId);
    }
    formData.append("name", adminDetail?.name);
    formData.append("email", adminDetail?.email);
    // formData.append("clientOrgShortName",adminDetail?.clientOrgShortName);

    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    console.log("formData-", ...formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(updateProfile(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.data?.success) {
          setIsUploading(true);
          setSuccessMessage(payload?.data?.message);
          // hideHandler();
          // setIsShow(true);
          addToast(payload?.data?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: "34px",
            },
          });
        } else {
          setErrorApiMessage(payload?.data?.message);
          // setIsShow(true);
          addToast(payload?.data?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
      setIsActionCalled(false);
      // inputRef?.current?.value = "";
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  // profileImage upload
  const upload = (e) => {
    setIsWrongFileType(false);
    console.log(e.target.files[0]);
    var fileName = e.target.files[0]?.name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      //TO DO
      setLogo(e.target.files[0]);
    } else {
      console.log("Only jpg/jpeg and png files are allowed!");

      setIsWrongFileType(true);
      setTimeout(() => {
        setIsWrongFileType(false);
      }, 3000);
    }
  };
  // call profileImage
  useEffect(() => {
    if (profileImage) {
      let urllink = URL.createObjectURL(profileImage);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [profileImage]);
  const hideHandlerRes = () => {
    if (isDone) {
      dispatch(adminReset());
      navigate("/profile");
    } else {
      // navigate("/profile");
    }
    setIsShow(false);
  };
  const cancelHandler = () => {
    navigate(`/dashboard`);
  };
  return (
    <>
      {isLoading && <Loader />}
      <PageTitle title="Profile" />
      <Widget>
        <Grid container spacing={4}>
          <Grid item xs={12} md={1}>
            {logoUrl ? (
              <Avatar className={classes.avatar} src={logoUrl} />
            ) : adminDetail?.org?.logo ? (
              <Avatar className={classes.avatar} src={adminDetail?.org?.logo} />
            ) : (
              <Avatar className={classes.avatar} src="/photo.svg" />
            )}

           
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container spacing={2} className={classes.formBox}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Organisation Name
                </Typography>
                <TextField
                  disabled
                  size="small"
                  name="clientName"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter Name"
                  variant="outlined"
                  value={adminDetail?.org?.name}
                  onChange={(e) => {
                    setAdminDetail({
                      ...adminDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.clientName}
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.clientName}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Admin</Typography>
                <TextField
                  size="small"
                  name="name"
                  value={adminDetail?.name}
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Middle Name"
                  variant="outlined"
                  onChange={(e) => {
                    setAdminDetail({
                      ...adminDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.name}
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.name}
                  </Typography>
                </Fade>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Client sub-organization
                </Typography>
                <TextField disabled
                  size="small"
                  name="clientOrgShortName"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Last Name"
                  variant="outlined"
                  value={orgDataValue}
                  onChange={(e) => {
                    setAdminDetail({
                      ...adminDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.clientOrgShortName}
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.clientOrgShortName}
                  </Typography>
                </Fade>
              </Grid> */}
              {/* <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Sex</Typography>
                <RadioGroup
                  row
                  className={classes.radioGroup}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </Grid> */}
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Email ID
                </Typography>
                <TextField
                  // disabled
                  size="small"
                  name="email"
                  className={classes.txtForm}
                  fullWidth
                  value={adminDetail?.email}
                  placeholder="Enter Email Id"
                  variant="outlined"
                  onChange={(e) => {
                    setAdminDetail({
                      ...adminDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.email}
                />
                 <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.email}
                  </Typography>
                </Fade>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Status
                </Typography>
                <TextField disabled
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter Number"
                  variant="outlined"
                  value={adminDetail?.status}
                />
              </Grid> */}
              <Grid
                className={classes.btnGroup}
                container
                xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  onClick={(e) => cancelHandler(e)}
                  className={classes.leftButton}
                  variant="outlined"
                  size="large"
                >
                  Cancel
                </Button>
                <Button
                  onClick={(e) => submitHandler(e)}
                  className={classes.rightButton}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Widget>
      {/* <Grid container className={classes.widgetBox} spacing={4}>
        <Grid item xs={12} md={4}>
          <div
            className={classes.passwordBox}
            onClick={() => navigate(`/reset-password`)}
          >
            <img src={lock} className={classes.icons} />
            <Typography className={classes.cardHeading}>
              Change Password
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.passwordBox}>
            <img src={lock} className={classes.icons} />
            <Typography className={classes.cardHeading}>
              Notifications
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.passwordBox}>
            <img src={lock} className={classes.icons} />
            <Typography className={classes.cardHeading}>
              Delete Account
            </Typography>
          </div>
        </Grid>
      </Grid> */}
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordAdded: isShow,
          title: "Profile Update",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
