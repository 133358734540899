import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Fade,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../hooks/Validation";
import {
  createUpdatePrivateData,
  fetchgroupsList,
} from "../../store/actions/privateDataAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../component/common/loader/Loader";
import uploadImage from "../../../assets/upload.png";
// styles
import useStyles from "./styles";
import closeIcon from "../../../assets/close.png";
import SelectedGroupList from "./SelectedGroupList";
import { useToasts } from "react-toast-notifications";
export default function ManageDataItam(props) {
  //
  console.log("props-24", props);
  const { selectclient, selectOrg } = props;
  const inputRef = useRef();
  const { addToast } = useToasts();
  console.log("selectclient", selectclient);
  console.log("selectOrg", selectOrg);
  const [isShow, setIsShow] = useState(false);
  const [selectUserType, setSelectUserType] = useState();
  const [groupsList, setGroupsList] = useState();
  const [dataType, setDataType] = useState("link");
  const formElements = [
    {
      key: "title",
      label: "Add Data Title",
      placeHolder: "Enter title",
      type: "text",
    },

    {
      key: "type",
      label: "Data Type",
      placeHolder: "Select",
      type: "singleSelect",
      options: [
        { name: "Web Link", value: "link" },
        { name: "Document", value: "document" },
      ],
    },
    {
      key: "link",
      label: "Add Weblink",
      placeHolder: "Add Link",
      type: "text",
    },
    {
      key: "file",
      label: "Add Document",
      placeHolder: "Upload Document",
      type: "upload",
    },
    {
      key: "userType",
      label: "Assessment Type",
      placeHolder: "Select",
      type: "radio",
      options: ["patient", "staff", "both"],
    },
    // {
    //   key: "repository_type",
    //   label: "Repository Type",
    //   placeHolder: "Select",
    //   type: "radio",
    //   options: ["Private Data Repository"],
    // },
    {
      key: "identifiedGroups",
      label: "Attach Identified Groups",
      placeHolder: "Select",
      type: "select",
      options: [],
    },
  ];
  var classes = useStyles();
  const [itemDataDetail, setItemDataDetail] = useState({
    title: null,
    type: null,
    link: null,
    // file: null,
    userType: null,
    identifiedGroups: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      title: itemDataDetail?.title,
      type: itemDataDetail?.type,
      ...(itemDataDetail.type === "link" && { link: itemDataDetail.link }),
      // link: itemDataDetail?.link,
      userType: itemDataDetail?.userType,
      identifiedGroups: itemDataDetail?.identifiedGroups,
    });
  const [isUploading, setIsUploading] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [orgDataValue, setOrgDataValue] = React.useState([]);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [orgOption, setOrgOption] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [orgListData, setOrgListData] = useState([]);
  const [orgListShow, setOrgListShow] = useState(false);

  // Create a function to handle selection/unselection
  const handleRemoveByIndex = (index) => {
    const newSelectedItems = [...orgDataValue];
    newSelectedItems.splice(index, 1);
    console.log("newSelectedItems", newSelectedItems);
    setOrgDataValue(newSelectedItems);
    console.log("orgDataValue", orgDataValue);
    console.log("newSelectedItems", newSelectedItems);
    setItemDataDetail({
      ...itemDataDetail,
      identifiedGroups: newSelectedItems,
    });
  };

  useEffect(() => {
    if (selectUserType) {
      console.log("selectUserType", selectUserType);
      dispatch(fetchgroupsList({ organization: selectOrg })).then(
        ({ payload }) => {
          console.log("payload-", payload);
          if (payload?.data) {
            if (selectUserType === "staff") {
              setGroupsList(payload?.data?.staff);
            } else if (selectUserType === "patient") {
              setGroupsList(payload?.data?.patient);
            } else {
              setGroupsList([
                ...payload?.data?.staff,
                ...payload?.data?.patient,
              ]);
            }
            if (selectclient?.userType === selectUserType) {
              if (orgDataValue) {
                console.log("orgData", orgDataValue);
              }
              // setOrgDataValue(selectclient?.identifiedGroups);
            } else {
              setOrgDataValue([]);
              if (selectclient) {
                setItemDataDetail({
                  ...itemDataDetail,
                  identifiedGroups: null,
                });
              }
            }
          }
        },
      );
    }
  }, [selectUserType, selectclient]);
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "identifiedGroups") {
      // Set orgDataValue state
      setOrgDataValue(value);

      // Set identifiedGroups in itemDataDetail as an array of strings
      setItemDataDetail((prevState) => ({
        ...prevState,
        identifiedGroups: value,
      }));
    } else {
      setItemDataDetail((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const hideHandler = () => {
    console.log("hide handler called");
    resetAllValidator();
    props.onHide();
    console.log("selectclient", selectclient);
    if (selectclient === null) {
      setItemDataDetail({
        title: null,
        type: null,
        link: null,
        file: null,
        userType: null,
        identifiedGroups: null,
      });
      setSelectUserType(null);
      setOrgDataValue([]);
      setLogo(null);
    }
    console.log("itemDataDetail", itemDataDetail);
  };
  useEffect(() => {
    if (selectclient === null) {
      setItemDataDetail({
        title: null,
        type: null,
        link: null,
        file: null,
        userType: null,
        identifiedGroups: null,
      });
      setOrgDataValue([]);
      setLogo(null);
    } else {
      setItemDataDetail({
        id: selectclient?.id,
        title: selectclient?.title,
        type: selectclient?.type,

        link: selectclient?.link,
        file: selectclient?.file,
        userType: selectclient?.userType,
        identifiedGroups: selectclient?.identifiedGroups,
      });
      setSelectUserType(selectclient?.userType);
      setOrgDataValue(selectclient?.identifiedGroups || []);
    }
  }, [selectclient]);
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { adminDetail } = admin;
  console.log("adminDetail", adminDetail);

  useEffect(() => {
    console.log("adminDetail", adminDetail);
    console.log(
      "adminDetail.data.subOrg.ehiiaVersion",
      adminDetail?.data?.subOrg?.ehiiaVersion,
    );
    const subOrgOptions = adminDetail?.data?.subOrg?.ehiiaVersion || [];
    if (Array.isArray(subOrgOptions)) {
      console.log("subOrgOptions", subOrgOptions);

      setOrgOption(subOrgOptions);
    }
  }, [adminDetail]);
  // Check if subOrgOptions is not null or undefined

  useEffect(() => {
    console.log("orgOption", orgOption);
  }, [orgOption]);
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  const showGroupList = (list) => {
    // console.log("list",list);
    setOrgListData(list);
    setIsOpen(true);
    setOrgListShow(true);
    // props.onHide();
  };

  // logo Document
  const upload = (e) => {
    setIsWrongFileType(false);
    console.log(e.target.files[0]);
    var fileName = e.target.files[0]?.name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (
      extFile === "jpg" ||
      extFile === "jpeg" ||
      extFile === "png" ||
      extFile === "pdf"
    ) {
      //TO DO
      setLogo(e.target.files[0]);
      setItemDataDetail((prevState) => ({
        ...prevState,
        file: e.target.files[0],
      }));
    } else {
      addToast("Only jpg/jpeg/png and pdf files are allowed!", {
        appearance: "warning",
        autoDismiss: true,
      });
      console.log("Only jpg/jpeg/png and pdf files are allowed!");

      setIsWrongFileType(true);
      setTimeout(() => {
        setIsWrongFileType(false);
      }, 3000);
    }
  };
  useEffect(() => {
    if (logo) {
      console.log("logo", logo);
      let urllink = URL.createObjectURL(logo);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [logo]);
  const hideHandle1 = () => {
    // setOrgListData(null);
    setIsOpen(false);
    setOrgListShow(false);
  };
  //api call
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("orgDataValue", orgDataValue);
        setIsUploading(true);
        const formData = {
          id: itemDataDetail.id,
          title: itemDataDetail.title,
          type: itemDataDetail.type,
          ...(itemDataDetail.type === "link" && { link: itemDataDetail.link }),
          ...(itemDataDetail.type === "document" && logo && { file: logo }),
          userType: itemDataDetail.userType,
          identifiedGroups: JSON.stringify(itemDataDetail.identifiedGroups),
        };

        console.log("formData-", formData?.file?.name);
        console.log("formData-", { ...formData });
        const organization = selectOrg;

        if (
          !isError() &&
          error !== null &&
          isActionCalled &&
          orgDataValue.length > 0
        ) {
          const { payload } = await dispatch(
            createUpdatePrivateData({ organization, ...formData }),
          );

          console.log("payload-", payload);

          if (payload?.success) {
            const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
            setIsUploading(true);
            setSuccessMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: "99999",
                height: toastHeight,
              },
            });
            hideHandler();
            // setIsShow(true);
          } else {
            const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
            setErrorApiMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style: {
                zIndex: "99999",
                height: toastHeight,
              },
            });
            // setIsShow(true);
          }
          setIsActionCalled(false);
        } else {
          if (isActionCalled && orgDataValue.length <= 0) {
            addToast("All fields are required", {
              appearance: "warning",
              autoDismiss: true,
              style: {
                zIndex: 999999,
                height: "34px",
              },
            });

            setIsActionCalled(false);
          }
          setIsActionCalled(false);
          console.log("error", error);
        }
      } catch (error) {
        // Handle errors here...
        console.error("Error in fetchData:", error);
      }
    };

    fetchData(); // Call the async function
  }, [isActionCalled]);

  // close dropdown
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <SelectedGroupList
        onHide={hideHandle1}
        title="View Selected Groups"
        orgListData={orgDataValue}
        open={isOpen}
        orgListShow={orgListShow}
        btnClick={(index) => handleRemoveByIndex(index)}
      />
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              {" "}
              {selectclient ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {formElements.map((element, i) => {
              return (
                (!selectclient || element.key !== "password") && (
                  <div key={i}>
                    <Typography className={classes.formHeading}>
                      {element.key === "file" &&
                      itemDataDetail.type !== "document"
                        ? ""
                        : element.key === "link" &&
                          itemDataDetail.type !== "link"
                        ? ""
                        : element.label}
                      {/* {element.label} */}
                    </Typography>
                    {element.type === "singleSelect" ? (
                      <>
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            value={
                              itemDataDetail &&
                              itemDataDetail[element.key] === null
                                ? ""
                                : itemDataDetail && itemDataDetail[element.key]
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              setItemDataDetail({
                                ...itemDataDetail,
                                [element.key]: e.target.value,
                              });
                              setDataType(value);
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          >
                            <MenuItem disabled value="">
                              select
                            </MenuItem>
                            {element.options &&
                              element.options.map((item, idx) => (
                                <MenuItem key={idx} value={item?.value}>
                                  {item?.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <Fade in={error}>
                          <Typography className={classes.errorMessage}>
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : element.type === "select" ? (
                      <>
                        <FormControl
                          focus={open}
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            name={element.key}
                            className={classes.selectDropdown}
                            value={orgDataValue}
                            onChange={(e) => {
                              handleChange(e);
                              resetValidator(e.target.name);
                            }}
                            renderValue={(selected) =>
                              selected
                                .map((value) =>
                                  groupsList && groupsList.includes(value)
                                    ? value
                                    : "",
                                )
                                .join(", ")
                            }
                            multiple
                            MenuProps={{
                              style: {
                                marginLeft: "-60px",
                                marginTop: "8px",
                                fontSize: "12px",
                                display: groupsList ? "block" : "none",
                                height: "760px",
                              }, // Adjust the width as needed
                            }}
                          >
                            {/* <div style={{ position: "fixed",display:'flex', justifyContent:'flex-end' }}>
                            <img
                                   onClick={() => setOpen(false)}
                                  style={{ cursor: "pointer",position: "relative" ,
                                  top: 0,
                                  right: '20px',  }}
                                  src={closeIcon}
                                  alt="close-icons"
                                />
                              
                            </div> */}
                            <MenuItem
                              style={{ display: "none" }}
                              disabled
                              value=""
                            >
                              Select
                            </MenuItem>

                            {element.key === "identifiedGroups" && groupsList
                              ? groupsList.map((item) => (
                                  <MenuItem value={item}>
                                    <Checkbox
                                      checked={orgDataValue.indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))
                              : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                        <Fade in={error}>
                          <Typography className={classes.errorMessage}>
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                        {orgDataValue &&
                          orgDataValue.slice(0, 2).map((item, index) => (
                            <>
                              <p
                                style={{
                                  border: "1px solid #E8E8E8",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  width: "474px",
                                  margin: "3px 0px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                {item}
                                <img
                                  onClick={() => handleRemoveByIndex(index)}
                                  style={{ cursor: "pointer" }}
                                  src={closeIcon}
                                  alt="close-icons"
                                />
                              </p>
                            </>
                          ))}
                        {orgDataValue && orgDataValue.length > 1 && (
                          <span
                            onClick={() => showGroupList(orgDataValue)}
                            style={{
                              color: "#03997B",
                              fontSize: "12px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                          >
                            View Selected Groups...
                          </span>
                        )}
                        {/* {isActionCalled && orgDataValue.length <= 0 && (
    <Fade in={error}>
    <Typography className={classes.errorMessage}>
      Please select identifiedGroups
    </Typography>
  </Fade>
  )} */}
                      </>
                    ) : element.key === "link" &&
                      itemDataDetail.type === "link" ? (
                      <>
                        <TextField
                          hiddenLabel
                          size="small"
                          type={element.type}
                          name={element.key}
                          placeholder={element.placeHolder}
                          className={classes.txtForm}
                          fullWidth
                          multiple
                          value={
                            itemDataDetail &&
                            itemDataDetail[element.key] === null
                              ? ""
                              : itemDataDetail && itemDataDetail[element.key]
                          }
                          variant="outlined"
                          onChange={async (e) => {
                            setItemDataDetail({
                              ...itemDataDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);

                            console.log("itemDataDetail", itemDataDetail);
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : element.type === "radio" ? (
                      <>
                        <RadioGroup
                          style={{
                            border: "1px solid #E8E8E8",
                            borderRadius: "5px",
                            padding: "0px 10px",
                            fontWeight: "400",
                            fontSize: "14px",
                            marginTop: "2px",
                          }}
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={""}
                          name={element.key}
                          size="small"
                          value={itemDataDetail[element.key] || ""}
                          onChange={(e) => {
                            setItemDataDetail({
                              ...itemDataDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(element.key); // Assuming you want to reset validation for the radio group
                            if (element.key === "userType") {
                              setSelectUserType(e.target.value);
                            }
                            resetValidator(e.target.name);
                          }}
                          isInvalid={error && error[element.key]}
                        >
                          {element.options.map((item) => (
                            <>
                              <FormControlLabel
                                size="small"
                                style={{ fontSize: "10px !important" }}
                                value={item}
                                control={<Radio size="small" />}
                                label={item}
                              />
                            </>
                          ))}
                        </RadioGroup>
                        <Fade in={error}>
                          <Typography className={classes.errorMessage}>
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : element.type === "upload" &&
                      itemDataDetail.type === "document" ? (
                      <>
                        <div
                          style={{
                            border: "1px solid #E8E8E8",
                            borderRadius: "5px",
                            padding: "8px 6px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {logo ? (
                            <span
                              style={{
                                color: "#A7AAAF",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              {logo?.name}
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "#A7AAAF",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              Upload Document
                            </span>
                          )}
                          <img
                            onClick={() => inputRef.current.click()}
                            src={uploadImage}
                            alt="upload"
                          />
                          <input
                            hidden
                            onChange={(e) => upload(e)}
                            ref={inputRef}
                            name="myImage"
                            accept="image/*, .pdf"
                            type="file"
                          />
                        </div>
                        {selectclient?.link && (
                          <a
                            href={selectclient?.link}
                            target=""
                          >
                            {" "}
                            <Chip size="sm" style={{cursor:'pointer',marginTop:'10px'}} label="Uploaded Document Link" />
                          </a>
                        )}
                      </>
                    ) : element.type === "text" && element.key !== "link" ? (
                      <>
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          type={element.type}
                          name={element.key}
                          placeholder={element.placeHolder}
                          variant="outlined"
                          multiline
                          rows={1}
                          maxRows={3}
                          value={
                            itemDataDetail &&
                            itemDataDetail[element.key] === null
                              ? ""
                              : itemDataDetail && itemDataDetail[element.key]
                          }
                          onChange={async (e) => {
                            setItemDataDetail({
                              ...itemDataDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);

                            console.log("itemDataDetail", itemDataDetail);
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )
              );
            })}
            {/* Name */}
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Manage Data Item",
            successMsg: successMessage,
            errorRes: errorApiMessage,
            // errorRes: apiError?.message,
          }}
        />
      </Popup>
    </>
  );
}
